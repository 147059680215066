<template>
<loader v-bind="{ loading }">
  <columns>
    <column>
        <page-heading
          heading="New Checkpoint"
          sub-heading="All work completed by your teams is done by answering checkpoints on checklists."
        />
    </column>
    <column class="is-narrow">
      <router-link :to="{ name: 'checkpoints' }">Back</router-link>
    </column>
  </columns>
  <form>
    <columns>
      <column>
        <columns>
          <column class="is-7">
            <text-input
              classes="is-rounded"
              :error="$root.errors.instruction"
              @input="updateInstruction"
              :value="checkpoint.instruction"
              placeholder="Eg. Take a reading of the current mileage of the odometer"
              description="The main instruction to complete on the checkpoint"
              required>
              Instruction
            </text-input>
          </column>
          <column>
            <data-selector
              classes="is-rounded"
              description="The type determines the tools made available to the user"
              :error="$root.errors.checkpoint_type_id"
              @input="updateType"
              :items="checkpoint_types"
              :value="checkpoint.checkpoint_type_id"
              value-key="id"
              label-key="name"
              required>
              Type
            </data-selector>
          </column>
        </columns>
        <columns>
          <column>
            <text-input
              classes="is-rounded"
              :error="$root.errors.additional_information"
              @input="updateAdditionalInformation"
              :value="checkpoint.additional_information"
              description="Any addtional information to aid the team member in completing the instruction">
              Additional Information <small class="has-text-weight-light">(optional)</small>
            </text-input>
          </column>
          <column class="is-4">
            <switch-input
              classes="is-rounded"
              :error="$root.errors.point_of_failure"
              @input="togglePointOfFailure"
              :value="checkpoint.point_of_failure"
              :items="failure_options"
              hint="Mark this checkpoint as a critical checkpoint">
              Point of failure <small class="has-text-weight-light">(optional)</small>
            </switch-input>
          </column>
        </columns>
        <columns>
          <column>
            <select-input
              required
              no-revert
              classes="is-rounded"
              description="This setting determines how notes are handled when completing a checklist"
              :error="$root.errors.comment_configuration"
              :items="comment_configurations"
              :value="checkpoint.comment_configuration"
              @input="updateCommentConfiguration">
              Note Configuration
            </select-input>
          </column>
          <column>
            <select-input
              classes="is-rounded"
              description="This setting determines how media is handled when completing a checklist"
              required
              no-revert
              :error="$root.errors.image_configuration"
              :items="image_configurations"
              :value="checkpoint.image_configuration"
              @input="updateImageConfiguration">
              Media Configuration
            </select-input>
          </column>
        </columns>

        <checkpoint-type-builder
          v-if="checkpoint.checkpoint_type_id"
        />

        <columns>
          <column>
            <data-selector
              classes="is-rounded"
              description="You may optionally associate checklists with this checkpoint at time of creation"
              multiple
              :items="checklists"
              :value="checkpoint.checklists"
              tag-class="is-medium is-rounded"
              delete-button-class="is-medium is-rounded is-danger"
              @input="updateChecklists"
              value-key="uuid"
              label-key="name">
              Checklists <span class="has-text-weight-light">(optional)</span>
            </data-selector>
          </column>
        </columns>

        <columns>
          <column>
            <submit-button
              class="is-rounded"
              :working="creating"
              :disabled="$root.cant('manage-checkpoints')"
              @submit="createCheckpoint">
              Create Checkpoint
            </submit-button>
            <p class="help is-danger" v-if="$root.cant('manage-checkpoints')">
              Please contact your administrator to adjust your
              permissions.</p>
          </column>
        </columns>

      </column>
      <column class="is-4">
        <p class="has-text-weight-bold has-text-right">Checkpoint Preview</p>
        <checkpoint-preview />
      </column>
    </columns>
  </form>
</loader>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import CheckpointPreview from './preview/Preview.vue'
import CheckpointTypeBuilder from './builder/Builder'
import { checklist as backend } from '@/api'

export default {

  components: {
    CheckpointPreview,
    CheckpointTypeBuilder
  },

  data: () => ({
    loading: true,
    creating: false,
    checklists: [],
    failure_options: [
      {
        value: false,
        label: 'No'
      },
      {
        value: true,
        label: 'Yes'
      }
    ]
  }),

  beforeDestroy() {
    this.clearCheckpoint()
  },

  async beforeCreate() {
    await this.$store.dispatch('checkpoint/loadTypes')
    await backend.loadList(null, ({data}) => this.checklists = data, () => {})
    this.loading = false
  },

  methods: {
    ...mapMutations('checkpoint', [
      'updateInstruction',
      'clearCheckpoint',
      'updateType',
      'updateCommentConfiguration',
      'updateImageConfiguration',
      'updateAdditionalInformation',
      'updateChecklists',
      'togglePointOfFailure'
    ]),
    createCheckpoint() {
      this.creating = true
      this.$store.dispatch('checkpoint/create').then(() => {
        this.$toast.success('Checkpoint successfully created')
        this.creating = false
        this.$router.push({
          name: 'checkpoints'
        })
      }).catch(() => {
        this.$toast.error('Please ensure all required fields are completed')
        this.creating = false
      })
    }
  },

  computed: mapGetters('checkpoint', [
    'checkpoint',
    'checkpoint_types',
    'comment_configurations',
    'image_configurations',
  ])

}
</script>